var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-actions',{ref:"formCard",attrs:{"title":"Add Module","no-actions":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Judul","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Judul","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.moduleTitle),callback:function ($$v) {_vm.moduleTitle=$$v},expression:"moduleTitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Subjudul","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Subjudul","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.moduleSubtitle),callback:function ($$v) {_vm.moduleSubtitle=$$v},expression:"moduleSubtitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Thumbnail","label-cols-md":"4"}},[_c('b-form-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Thumbnail","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.iconInitialFile ?
                          _vm.iconInitialFile.split('/').pop()
                          : "Pilih atau drop file disini...","drop-placeholder":"Drop file disini...","accept":"image/*"},model:{value:(_vm.thumbnail),callback:function ($$v) {_vm.thumbnail=$$v},expression:"thumbnail"}}),(_vm.thumbnail !== null)?_c('div',[(_vm.thumbnail.size > 1024 * 2048)?_c('small',{staticClass:"text-danger"},[_vm._v(" Ukuran File Tidak Bisa Lebih dari 2 MB ")]):_vm._e()]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.iconFile || _vm.iconInitialFile)?_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Lihat File'),expression:"'Lihat File'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"gradient-secondary","target":"_blank","href":_vm.iconFile ? _vm.fileUrl(_vm.iconFile) : _vm.iconInitialFile}},[_c('feather-icon',{attrs:{"icon":"FileIcon"}})],1)],1):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Status Modul","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Status Modul","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.statusKelasOptions,"label":"title","searchable":false,"state":errors.length > 0 ? false:null},model:{value:(_vm.statusModule),callback:function ($$v) {_vm.statusModule=$$v},expression:"statusModule"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Trainer","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Trainer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.trainerItems,"state":errors.length > 0 ? false:null,"filterable":false,"searchable":false},model:{value:(_vm.trainerId),callback:function ($$v) {_vm.trainerId=$$v},expression:"trainerId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-50",attrs:{"variant":"primary","type":"submit","disabled":_vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }